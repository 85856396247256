import React from "react";
import ButtonComponent from "../../../components/ButtonComponent";
import {
  NewModal,
  NewModalContent,
  NewModalHeader,
  NewModalTitle,
  NewModalBody,
  NewModalFooter,
  NewModalCloseButton,
  NewModalRef,
} from "../../../components/modal/NewModal/NewModal";

import PdfAlert from "../../../assets/installation/pdf-alert.png";

import styles from "./ErrorReadPDFModal.module.css";

type ErrorReadPDFModalProps = {
  handleValidationManualFatura: () => void;
};

const ErrorReadPDFModal = React.forwardRef<NewModalRef, ErrorReadPDFModalProps>(
  ({ handleValidationManualFatura }, ref) => {
    return (
      <NewModal ref={ref}>
        <NewModalContent>
          <NewModalHeader>
            <img width="40px" height="40px" src={PdfAlert} alt="" />
            <NewModalTitle>
              Não foi possível fazer a leitura do arquivo.
            </NewModalTitle>
          </NewModalHeader>
          <NewModalBody>
            <p className={`${styles.textSize}`}>
              A validação automática é realizada apenas em arquivos no formato
              “.pdf”. Clique em “Enviar outro arquivo” para anexar o PDF da
              fatura ou em “Solicitar validação manual” para que o nosso time
              realize a análise da sua fatura.
            </p>
          </NewModalBody>
          <NewModalFooter>
            <NewModalCloseButton>
              <ButtonComponent
                className={`back-button ${styles.buttonBackModalError}`}
              >
                Enviar outro arquivo
              </ButtonComponent>
            </NewModalCloseButton>
            <ButtonComponent
              className={`continue-button ${styles.buttonContinueModalError}`}
              onClick={handleValidationManualFatura}
            >
              Solicitar validação manual
            </ButtonComponent>
          </NewModalFooter>
        </NewModalContent>
      </NewModal>
    );
  }
);

export default ErrorReadPDFModal;
