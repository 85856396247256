import React, { ReactNode, useRef } from "react";
import "./SecondaryScreen.css";
import { useNavigate } from "react-router-dom";
import { FaqModal, FaqModalRef } from "./FaqModal";

interface SecondaryScreenProps {
  logoSrc: string;
  iconOne: string;
  iconTwo: string;
  children?: ReactNode;
  onclick?: () => void;
}

const SecondaryScreen: React.FC<SecondaryScreenProps> = ({
  logoSrc,
  iconOne,
  children,
}) => {
  const faqModal = useRef<FaqModalRef | null>(null);

  const navigate = useNavigate();
  return (
    <div className="my-component">
      <div className="top-section">
        <img
          src={logoSrc}
          alt="Logo-Ivy"
          className="logo"
          onClick={() => navigate("/")}
        />
        <button onClick={() => faqModal.current?.open()}>
          <img src={iconOne} alt="Icon-One" className="question-lado-direito" />
          {/* <img src={iconOne} alt="Icon-One" className="icon" /> */}
        </button>
      </div>

      <div className="middle-section">{children}</div>
      <FaqModal ref={faqModal} />
    </div>
    
  );
};

export default SecondaryScreen;
