import * as React from "react"
import { SVGProps } from "react"
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_373_35387)">
    <path d="M10.7561 6.16922L17.8261 13.2402L10.6531 20.4142C10.3211 20.7462 9.88282 20.9509 9.41509 20.9922L9.23909 20.9992H4.00609C3.48609 20.9992 3.05709 20.6052 3.00209 20.0992L2.99609 19.9892V14.7562C2.99621 14.2867 3.16153 13.8321 3.46309 13.4722L3.58309 13.3422L10.7561 6.16922ZM13.8961 3.02922C14.25 2.67528 14.7238 2.4669 15.2238 2.44523C15.7239 2.42357 16.2139 2.5902 16.5971 2.91222L16.7241 3.02922L20.9671 7.27222C21.3208 7.62608 21.529 8.09964 21.5506 8.59947C21.5723 9.0993 21.4058 9.5891 21.0841 9.97222L20.9671 10.1002L19.2411 11.8262L12.1711 4.75522L13.8961 3.02922Z" fill="black"/>
  </g>
  <defs>
    <clipPath id="clip0_373_35387">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
)
export default EditIcon
