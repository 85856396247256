import React from "react";
import SecondaryScreen from "../../components/SecondaryScreen";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import erro404 from "../../assets/page-not-found.svg";
import "./PageNotFound.css";
import ButtonComponent from "../../components/ButtonComponent";
import { useNavigate } from "react-router";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const handleButtonClick = () => {
    navigate("/");
  };
  if (isRouteErrorResponse(error) && error.status === 500) {
  }

  return (
    <>
      {isRouteErrorResponse(error) && error.status === 500 && (
        <SecondaryScreen
          logoSrc={logoIvi}
          iconOne={question}
          iconTwo={comentIcon}
        >
          <div className="container-page-not-found">
            <img src={erro404} />

            <h1 className="title-page-not-found">Algo deu errado!</h1>
            <p className="description-page-not-found">
              Infelizmente não foi possível acessar essa página.
            </p>
            <ButtonComponent className="btn-homee" onClick={handleButtonClick}>
              Voltar para tela inicial
            </ButtonComponent>
          </div>
        </SecondaryScreen>
      )}
    </>
  );
};
