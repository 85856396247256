import { useMutation } from '@tanstack/react-query';
import { initAuth } from '../services/authentication';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function useInitAuth() {

  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const mutation = useMutation({
    mutationFn: async ({ email, name }: { email: string; name?: string }) => {
      let token = "";
      if (executeRecaptcha) {
        token = await executeRecaptcha("init_auth");
      }
      const response = await initAuth({ email, name }, token);
      return response;
    },
    mutationKey: ["post-init-auth"],
    onSuccess(data, email) {
      if (data.status !== "SUCESSO") {
        throw new Error(data.status);
      }
      navigate("/validation");
    },
  });
  return mutation;
}
