import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import imgModal from "../../assets/holder/onde-encontro-dados-cpf-cnpj.svg";
import iconInform from "../../assets/information_fill.svg";
import "./holder.css";
import { Input } from "@nextui-org/react";

import Modal from "../../components/modal/Modal";
import { SessionContext } from "../../context/session";
import { useMutation } from "@tanstack/react-query";
import { validateCnpj, validateCpf } from "../../services/onboarding";
import { POST_VALIDATE_CNPJ, POST_VALIDATE_CPF } from "../../query/constants";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import { AxiosError } from "axios";
import { getImage } from "../../services/images";
import { textsPath } from "../../services/texts";

import RequestErrorModal from "../../components/modal/RequestErrorModal/RequestErrorModal";
import DocumentoInvalidoModal from "../../components/modal/DocumentoinvalidoModal/DocumentoInvalidoModal";
import TitularExistenteModal from "../../components/modal/TitularExistenteModal/TitularExistenteModal";

import type { NewModalRef } from "../../components/modal/NewModal/NewModal";
import ServicoIndisponivelModal from "../../components/modal/ServicoIndisponivelModal/ServicoIndisponivelModal";

export const Holder = () => {
  const requestErrorModalRef = useRef<NewModalRef>(null);
  const documentoInvalidoModalRef = useRef<NewModalRef>(null);
  const titularExistenteModalRef = useRef<NewModalRef>(null);
  const servicoIndisponivelModalRef = useRef<NewModalRef>(null);

  const navigate = useNavigate();
  const { setItemSession } = useContext(SessionContext);
  const [cpfCnpj, setCpfCnpj] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;

  useEffect(() => {
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);
  const [typeDados, setTypeDados] = useState<"CNPJ" | "CPF" | "">("");

  const validateCnpjMutate = useMutation({
    mutationFn: validateCnpj,
    mutationKey: [POST_VALIDATE_CNPJ],
    onSuccess: (data, cnpj) => {
      const dataCpnj = JSON.stringify(data);
      setItemSession("cnpj", dataCpnj);
      navigate("/create-holder-cnpj");
    },
    onError(erro: AxiosError) {
      const statusErro = erro.response?.status;

      if (statusErro === 401) {
        setTypeDados("CNPJ");
        titularExistenteModalRef.current?.open();
        return;
      }

      if (statusErro === 400 || statusErro === 404) {
        setTypeDados("CNPJ");
        documentoInvalidoModalRef.current?.open();
        return;
      }

      if (statusErro === 503) {
        setTypeDados("CNPJ");
        servicoIndisponivelModalRef.current?.open();
        return;
      }

      requestErrorModalRef.current?.open();
    },
  });

  const validateCpfMutation = useMutation({
    mutationFn: validateCpf,
    mutationKey: [POST_VALIDATE_CPF],
    onSuccess: (data) => {
      setItemSession("cpf", cpfCnpj);
      navigate("/create-holder");
    },

    onError(erro: AxiosError) {
      const statusErro = erro.response?.status;

      if (statusErro === 401) {
        setTypeDados("CPF");
        titularExistenteModalRef.current?.open();
        return;
      }

      if (statusErro === 404) {
        setTypeDados("CPF");
        documentoInvalidoModalRef.current?.open();
        return;
      }

      requestErrorModalRef.current?.open();
    },
  });

  const formatCpfCnpj = (value: string) => {
    if (!value) return "";

    if (isCPF(value)) return formatCPF(value);

    if (isCNPJ(value)) return formatCNPJ(value);

    return value;
  };

  const acceptCpfClients = process.env.REACT_APP_ACCEPT_CPF_CLIENTS !== "false";

  const label = acceptCpfClients ? "CPF ou CNPJ" : "CNPJ";

  const isCPF = (value: string) => value.length === 11 && acceptCpfClients;
  const isCNPJ = (value: string) => value.length === 14;

  const getDigits = (value: string) => value.replace(/[^\d]/g, "");
  const cpfCnpjValue = getDigits(cpfCnpj);
  const hasValidCpfCnpj = isCNPJ(cpfCnpjValue) || isCPF(cpfCnpjValue);
  const btnIsDisabled = !hasValidCpfCnpj;

  const formatCNPJ = (value: string) =>
    value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

  const formatCPF = (value: string) =>
    value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  const handleCpfCnpjChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cpfCnpjValue = removeExcessiveDigits(getDigits(event.target.value));
    setCpfCnpj(formatCpfCnpj(cpfCnpjValue));
  };

  const removeExcessiveDigits = (value: string) => {
    if (value.length > 14) {
      return value.slice(0, 14);
    }
    return value;
  };

  const handleBackButtonClick = () => {
    // Lógica a ser executada ao clicar no botão
    navigate("/contact");
  };

  const handleButtonClick = () => {
    setTypeDados("");

    if (isCNPJ(getDigits(cpfCnpj))) {
      validateCnpjMutate.mutate(cpfCnpj);
    } else if (isCPF(getDigits(cpfCnpj))) {
      validateCpfMutation.mutate(cpfCnpj);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("contaDeLuz")}
      />
      <RightHomeComponent
        cardHomeClass="container-holder"
        questionSrc={question}
        commentIconSrc={comentIcon}
        title={title}
        description={description}
      >
        <div className="container-input-button-holder">
          <div className="container-input-holder">
            <Input
              type="text"
              size="md"
              label={label}
              value={cpfCnpj}
              onChange={handleCpfCnpjChange}
              color="primary"
              classNames={{
                input: ["text-noto"],
              }}
            />
          </div>
          <div className="icon-information" onClick={openModal}>
            <p>Como encontro os dados?</p>
            <button>
              <img src={iconInform} alt="icone de informacao" />
            </button>
          </div>
          <div className="button-container-holder">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar"
              onClick={handleBackButtonClick}
            >
              Voltar
            </ButtonComponent>
            <ButtonComponent
              className="continue-button"
              // className="btn-contact"
              onClick={handleButtonClick}
              isDisabled={btnIsDisabled}
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>

        {/* Onde encontro os dados */}
        {isModalOpen && (
          <div className="modal-position">
            <Modal
              onClose={closeModal}
              // className="modal-titular"
              classNameModal="modal-titular"
            >
              <div className="modal-content-titular">
                <div className="header-modal">
                  <h1 className="dados-title-titular">
                    Onde encontrar os dados do titular?
                  </h1>
                  <p className="dados-subtitle-titular">
                    Abra sua conta de luz e veja os dados no canto superior
                    esquerdo.
                  </p>
                </div>
                <div className="modal-titular-content">
                  <img src={imgModal} alt="Imagem Modal" />
                  <ButtonComponent
                    className="continue-button"
                    // className="btn-modal-titular"
                    style={{ marginTop: "24px" }}
                    onClick={closeModal}
                  >
                    Fechar
                  </ButtonComponent>
                </div>
              </div>
            </Modal>
          </div>
        )}
        {(validateCnpjMutate.isPending || validateCpfMutation.isPending) && (
          <LoadingFullPage />
        )}

        {/* Caso o CPF/CPNJ já esteja atrelada a uma conta de email */}
        <TitularExistenteModal
          ref={titularExistenteModalRef}
          documentType={typeDados}
        />

        {/* Caso o documento seja inválido */}
        <DocumentoInvalidoModal
          ref={documentoInvalidoModalRef}
          documentType={typeDados}
        />

        {/* Caso haja um erro de requisição */}
        <RequestErrorModal ref={requestErrorModalRef} />

        {/* Caso o serviço esteja indisponível */}
        <ServicoIndisponivelModal ref={servicoIndisponivelModalRef} />
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
