import React, { useEffect, useState } from "react";
import "./WeAreAlmostThere.css";
import logoIvi from "../../../assets/logo.svg";
import question from "../../../assets/question.svg";
import comentIcon from "../../../assets/comentIcon.svg";
import sucess from "../../../assets/weAreAlmostThere/sucess.svg";
import SecondaryScreen from "../../../components/SecondaryScreen";
import ButtonComponent from "../../../components/ButtonComponent";
import { textsPath } from "../../../services/texts";
import { navigateToLp } from "../../../utils/landingPage";

interface MyComponentProps {}

const WeAreAlmostThere: React.FC<MyComponentProps> = () => {
  const [texts, setTexts] = useState({ description1: "", description2: "", title: "" })
  const { description1, description2, title } = texts;

  useEffect(() => {
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <div className="middle-content">
        <img src={sucess} alt="sucess-Ivy" />
        <br />
        <div>
          <h1 className="title-almost-there">{title}</h1>
          <p className="description-almost-there">
            {description1} <br />
            {description2}
          </p>
        </div>
        <ButtonComponent
          className="continue-button btn-back-almost-there"
          style={{ width: "192px", margin: "20px 0", padding: "20px 0" }}
          onClick={navigateToLp}
        >
          Voltar para tela Inicial
        </ButtonComponent>
      </div>
    </SecondaryScreen>
  );
};

export default WeAreAlmostThere;
