import confirmYourData from "../assets/confirmYourData/confirmYourData.svg";
import contact from "../assets/contact/contact.svg";
import contractOfAdhesion from "../assets/contractOfAdhesion/contractOfAdhesion.svg";
import contaDeLuz from "../assets/holder/conta-de-luz.svg";
import imgIvi from "../assets/home/home.svg";
import installation from "../assets/installation/installation.svg";
import selectYourDistributor from "../assets/selectYourDistributor/selectYourDistributor.svg";
import validation from "../assets/validation/validation.svg";

type keys = "confirmYourData" |
            "contact" |
            "contractOfAdhesion" |
            "contaDeLuz" |
            "imgIvi" |
            "installation" |
            "selectYourDistributor" |
            "validation";



const images = {
    confirmYourData,
    contact,
    contractOfAdhesion,
    contaDeLuz,
    imgIvi,
    installation,
    selectYourDistributor,
    validation
}


export const getImage = (imgId: keys) => images[imgId] ?? ""