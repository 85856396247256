import { ReactElement, useContext, useEffect } from 'react'
import SecondaryScreen from '../SecondaryScreen'
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import "./screenError.css";
import { SessionContext } from '../../context/session';

type ErrorProps = {
  title: string,
  texto: string,
  icon: string | ReactElement<SVGElement>;
  alt: string,
}



export function ScreenError({title, texto, icon, alt}: ErrorProps) {
  const {clearStorage} = useContext(SessionContext)

  useEffect(() => {
    sessionStorage.clear();
    clearStorage();
  }, [])
  
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <section className="container-unapproved-account">
        <img
          src={typeof icon === 'string' ? icon : undefined}
          alt={alt}
          style={{ width: "64px" }}
        />
        <h1 className="title-unapproved-account">
          {title}
        </h1>
        <p className="description-unapproved-account">
          {texto}
        </p>

      </section>
    </SecondaryScreen>
  )
}

