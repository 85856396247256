import { useMutation } from "@tanstack/react-query";
import { getLead } from "../services/onboarding";
import { GET_LEAD } from "../query/constants";
import { useContext } from "react";
import { SessionContext } from "../context/session";
import { useGetProtectedLead } from "./useGetProtectedLead";
import { useGetDistribuidora } from "./useGetDistribuidora";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function useGetLead() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const getDistribuidora = useGetDistribuidora();
  const { setItemSession } = useContext(SessionContext);
  const getProtectedLead = useGetProtectedLead();

  const getLeadMutation = useMutation({
    mutationFn: async (email: string) => {
      let token = "";
      if (executeRecaptcha) {
        token = await executeRecaptcha("get_lead");
      }
      const response = await getLead(email, token);
      return response;
    },
    mutationKey: [GET_LEAD],
    onSuccess: (data, email) => {
      const contato = data.contato;

      const contactData = {
        name: contato.nome,
        email: email,
        phone: contato.telefone,
      };
      const contactDataString = JSON.stringify(contactData);
      setItemSession("contact", contactDataString);

      // const getLeadDistribuidora: string = data.distribuidora;
      // setItemSession("distribuidora", getLeadDistribuidora);

      setItemSession("distribuidora", data.distribuidora);

      getDistribuidora.mutate();
      getProtectedLead.mutate();
      // navigate("/holder");
    },
    onError(error) {
      console.log(error);
    },
  });
  return getLeadMutation;
}
