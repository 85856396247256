import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoIvi from "../../assets/logo.svg";
import arrowRight from "../../assets/arrow-rigth.svg";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import awaitingValidation from "../../assets/registrationCompleted/awaiting-validation.svg";
import piggyBank from "../../assets/registrationCompleted/piggy-bank.svg";
import solarPanel from "../../assets/registrationCompleted/solar-panel.svg";
import "./RegistrationCompleted.css";
import SecondaryScreen from "../../components/SecondaryScreen";
import ButtonComponent from "../../components/ButtonComponent";
import CardContainer from "../../components/card/cardFinish/CardContainer";
import Card from "../../components/card/cardFinish/Card";
import { removeItemStorage } from "../../utils/localStorage";
import { SessionContext } from "../../context/session";
import { textsPath } from "../../services/texts";

interface MyComponentProps {}

const RegistrationCompleted: React.FC<MyComponentProps> = () => {
  const {clearStorage} = useContext(SessionContext)
  const navigate = useNavigate();

  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;

  useEffect(() => {
    removeItemStorage("cnpj");
    removeItemStorage("cpf");
    removeItemStorage("holderData");
    removeItemStorage("holderCNPJ");
    removeItemStorage("contact");
    removeItemStorage("arquivo");
    removeItemStorage("distribuidora");
    sessionStorage.clear()
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const handleButtonClick = () => {
    clearStorage()
    navigate("/");
  };
  return (
    <SecondaryScreen
      logoSrc={logoIvi}
      iconOne={question}
      iconTwo={comentIcon}
    >
      <div className="middle-content-registration-complet">
        <h1 className="title-registration-complet">{title}</h1>
        <div className="description-registration-complet">
          {description}
        </div>
        <CardContainer>
          <Card
            imgSrc={awaitingValidation}
            altText="Imagem aguardando validação"
            cardText={`Aguardando <br/> validação do cadastro`}
          />

          <img src={arrowRight} className="arrow" alt="Seta para a direita" />

          <Card
            imgSrc={solarPanel}
            altText="Imagem solar painel"
            cardText={`Aguardando <br/> benefícios`}
            customClass="opacity"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={piggyBank}
            altText="Imagem porco cofre"
            cardText={`Economizando <br/> com a IVI`}
            customClass="opacity"
          />
        </CardContainer>

        <ButtonComponent
          className="continue-button"
          onClick={handleButtonClick}
          style={{ width: "217px", margin: "20px 0" }}
        >
          Cadastrar nova instalação
        </ButtonComponent>
      </div>
    </SecondaryScreen>
  );
};

export default RegistrationCompleted;
