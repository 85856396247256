import SecondaryScreen from "../../components/SecondaryScreen";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import flash from "../../assets/UnapprovedAccount/flashEnergy.svg";
import ButtonComponent from "../../components/ButtonComponent";
import "./UnapprovedAccount.css";
import { useNavigate } from "react-router";

export const UnapprovedAccount = () => {
  const navigate = useNavigate();
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <section className="container-unapproved-account">
        <img
          src={flash}
          alt="um raio com corte no meio"
          style={{ width: "64px" }}
        />
        <h1 className="title-unapproved-account">
          Conta não atende os requisitos
        </h1>
        <p className="description-unapproved-account">
          Avaliamos seu perfil de consumo e, infelizmente não será possível dar
          continuidade ao seu cadastro. Manteremos o seu cadastro em nossa base
          para futuras oportunidades.
        </p>
        <ButtonComponent
          className="continue-button"
          // className="btn-homee"
          style={{ margin: "20px 0", width: "190px", padding: "0 24px" }}
          onClick={() => navigate("/installation")}
        >
          Incluir outra conta
        </ButtonComponent>
      </section>
    </SecondaryScreen>
  );
};
