import '../CustomCard.css';
import './CardConfirmYourData.css';
import EditIcon from '../../../assets/confirmYourData/edit';

interface CardProps {
  title: string;
  children: JSX.Element;
  // data: { label: string; value: string }[];
  onEditClick?: () => void; // Adicione esta linha para a propriedade onEditClick
}

const CardConfirmYourData = ({ title, children, onEditClick }: CardProps) => {
  // console.log(data);
  return (
    <div className="custom-card-confirm-data ">
      <div className="title-container-confirm-data">
        <h1 className="title-confirm-data">{title}</h1>
        {
          onEditClick && <button className="edit-icon-confirm-data" onClick={onEditClick}>
            <EditIcon />
          </button>
        }
      </div>

      <div className="data-container-confirm">
        {children}
      </div>
    </div>
  );
};

export default CardConfirmYourData;
