import { useMutation } from "@tanstack/react-query";
import { getProtectedLead } from "../services/onboarding";
import { GET_LEAD_PROTECTED } from "../query/constants";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SessionContext } from "../context/session";

export function useGetProtectedLead() {
  const navigate = useNavigate();

  const { setItemSession } = useContext(SessionContext);
  //o redirecionamento vai ser no protected
  const getLeadProtectedMutation = useMutation({
    mutationFn: getProtectedLead,
    mutationKey: [GET_LEAD_PROTECTED],
    onSuccess: (data) => {
      const statusLead = data.status;
      const onboardingStatus: string = data.onboardingStatus;
      const dadosTitular = data.instalacao.titular;
      const faturaInfo = data.instalacao;
      const dadosDistribuidora = data.instalacao.distribuidora;
      const dadosContato = data.contato;

      let holderDataString = "";
      let storageCpf;
      let storageCpfCnpj;
      let holderCnpjString = "";

      setItemSession("dados-distribuidora", JSON.stringify(dadosDistribuidora));
      setItemSession("contact", JSON.stringify({
        name: dadosContato.nome,
        email: dadosContato.email,
        phone: dadosContato.telefone,
      }));
      if (dadosTitular.tipo === "pf") {
        storageCpf = dadosTitular.cpf;
        const selectedNacionalidades: string | undefined = dadosTitular.nacionalidade;
        const documentoAtual =
          selectedNacionalidades === "brasileiro" ? "rg" : "rne";

        const rgDoc = dadosTitular.rg;

        const holderData = {
          storageCpf: dadosTitular.cpf,
          nome: dadosTitular.nome,
          [documentoAtual]: documentoAtual === "rg" ? rgDoc : rgDoc,
          selectedMaritalStatus: dadosTitular.estadoCivil,
          selectedNacionalidades,
        };
        holderDataString = JSON.stringify(holderData);
      } else if (dadosTitular.tipo === "pj") {
        storageCpfCnpj = dadosTitular.representante.cpf;
        const selectedNacionalidades: string | undefined =
          dadosTitular.representante.nacionalidade;
        const documentoAtual =
          selectedNacionalidades === "brasileiro" ? "rg" : "rne";
        const rgDoc = dadosTitular.representante.rg;

        const holderCnpjData = {
          nome: dadosTitular.representante.nome,
          [documentoAtual]: documentoAtual === "rg" ? rgDoc : rgDoc,
          cpf: storageCpfCnpj,
          selectedMaritalStatus: dadosTitular.representante.estadoCivil,
          selectedNacionalidades,
        };
        holderCnpjString = JSON.stringify(holderCnpjData);
      }

      const storageValue = {
        CNPJ: dadosTitular.cnpj,
        RazaoSocial: dadosTitular.razaoSocial,
      };
      const storageCnpj = JSON.stringify(storageValue);

      //FATURA:
      const consumoMedio: number | undefined = faturaInfo.fatura.consumoMedio;
      const fornecimento: string | undefined = faturaInfo.fornecimento;
      const numeroInstalacao: string | undefined = faturaInfo.numeroInstalacao;
      const endereco: string | undefined = faturaInfo.endereco;
      const fatura = {
        consumoMedio,
        fornecimento,
        numeroInstalacao,
        endereco,
      };
      const arquivo = JSON.stringify(fatura);

      switch (onboardingStatus) {
        case "LEAD_CRIADA":
          //LEAD_CRIADA
          navigate("/holder");
          break;
        case "ERRO_LEITURA":
          //ERRO_LEITURA

          if (faturaInfo.fatura.instalacaoId === null && statusLead === "PENDENTE") {
            if (dadosTitular.tipo === "pf") {
              setItemSession("cpf", storageCpf ?? "");
              setItemSession("holderData", holderDataString);
            } else {
              setItemSession("cnpj", storageCnpj);
              setItemSession("holderCNPJ", holderCnpjString);
            }
            navigate("/installation");
            return;
          }
          // let statusLead = true;
          // setItemLocalStorage("statusLead", JSON.stringify(statusLead));
          navigate("/pdf-error-validation");
          // console.log("ERRO DE LEITURA");
          break;
        case "FATURA_SALVA_PF":
          //FATURA_SALVA_PF
          setItemSession("cpf", storageCpf ?? "");
          setItemSession("holderData", holderDataString);
          setItemSession("dados-fatura", arquivo);
          navigate("/confirm-your-data");
          break;
        case "DADOS_TITULAR_PF":
          //DADOS_TITULAR_PF
          setItemSession("cpf", storageCpf ?? "");
          setItemSession("holderData", holderDataString);
          navigate("/installation");
          break;
        case "FATURA_SALVA_PJ":
          //FATURA_SALVA_PJ
          setItemSession("cnpj", storageCnpj);
          setItemSession("holderCNPJ", holderCnpjString);
          setItemSession("dados-fatura", arquivo);
          navigate("/confirm-your-data");
          break;
        case "DADOS_REPRESENTANTE":
          //DADOS_REPRESENTANTE
          setItemSession("cnpj", storageCnpj);
          setItemSession("holderCNPJ", holderCnpjString);
          navigate("/installation");
          break;
        case "DADOS_SALVOS_TITULAR_PJ":
          //DADOS_SALVOS_TITULAR_PJ
          setItemSession("cnpj", storageCnpj);
          setItemSession("holderCNPJ", holderCnpjString);
          navigate("/installation");
          break;
        case "CADASTRO_CONCLUIDO":
          //CADASTRO_CONCLUIDO
          navigate("/registration-completed");
          break;
        default:
          break;
      }
    },
    onError: (erro) => {
      console.log(erro);
    },
  });
  return getLeadProtectedMutation;
}
