import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import CountdownTimer from "../../components/Countdown";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./validation.css";
import { useMutation } from "@tanstack/react-query";
import { sendToken } from "../../services/authentication";
import { JsonCreateLeadProps } from "../../services/onboarding";
import { POST_SEND_TOKEN } from "../../query/constants";
import { useInitAuth } from "../../hooks/useInitAuth";
import { RedirectContext } from "../../context/redirect";
import { useGetLead } from "../../hooks/useGetLead";
import { SessionContext } from "../../context/session";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import UseNewLead from "../../hooks/useNewLead";
import { useGetProtectedLead } from "../../hooks/useGetProtectedLead";
import { useGetDistribuidora } from "../../hooks/useGetDistribuidora";
import { getImage } from "../../services/images";
import { textsPath } from "../../services/texts";

export const Validation = () => {
  const { getItemSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const { continueResgistration } = useContext(RedirectContext);
  const [passValidation, setPassValidation] = useState({
    numberOne: "",
    numberTwo: "",
    numberThree: "",
    numberFour: "",
    numberFive: "",
    numberSix: "",
  });
  const {
    numberOne,
    numberTwo,
    numberThree,
    numberFour,
    numberFive,
    numberSix,
  } = passValidation;

  const [errorMgs, setErrorMgs] = useState<string>("");
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [emailStorage, setEmailStorage] = useState<string>("");
  const [timeOut, setTimeOut] = useState<boolean>(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const code = `${numberOne}${numberTwo}${numberThree}${numberFour}${numberFive}${numberSix}`;
  const [contactStorage, setContactStorage] = useState<JsonCreateLeadProps>({
    nome: "",
    telefone: "",
    distribuidora: "",
    acceptTime: ""
  });
  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;

  const initAuth = useInitAuth();
  const getLead = useGetLead();
  const createLead = UseNewLead();
  const getDistribuidora = useGetDistribuidora();
  const getProtectedLead = useGetProtectedLead();


  const mutation = useMutation({
    mutationFn: sendToken,
    mutationKey: [POST_SEND_TOKEN],
    onSuccess(data) {
      if (data.status !== "SUCESSO") {
        throw new Error();
      }
      sessionStorage.setItem("token", data.token);

      if (!continueResgistration) {
        creatNewLead();
      } else {
        getLead.mutate(emailStorage);
      }
    },
    onError(error) {
      setInvalidNumber(true);
      setErrorMgs(
        " Por favor, digite o código de 6 dígitos enviado para seu e-mail"
      );
    },
  });

  function creatNewLead() {
    createLead.mutate({
      nome: contactStorage.nome,
      telefone: contactStorage.telefone,
      distribuidora: contactStorage.distribuidora,
      acceptTime: contactStorage.acceptTime
    });
  }

  useEffect(() => {
    const storageContact = getItemSession("contact");
    const distribuidora = getItemSession("distribuidora") as string;
    const emailContinue = getItemSession("emailContinue");

    if (storageContact && distribuidora) {
      const contactModifyStorage = JSON.parse(storageContact);
      const newDistribuidora = distribuidora;

      setContactStorage({
        nome: contactModifyStorage.name,
        telefone: contactModifyStorage.phone,
        distribuidora: newDistribuidora,
        acceptTime: contactModifyStorage.acceptTime
      });
      setEmailStorage(contactModifyStorage.email);
      return;
    }
    if (emailContinue) {
      return setEmailStorage(emailContinue);
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const navigateToNextInput = (nextRef: React.RefObject<HTMLInputElement>) => {
    if (nextRef.current) {
      nextRef.current.focus();
      nextRef.current.setSelectionRange(1, 1);
    }
  };

  const numberOneRef = useRef<HTMLInputElement>(null);
  const numberTwoRef = useRef<HTMLInputElement>(null);
  const numberThreeRef = useRef<HTMLInputElement>(null);
  const numberFourRef = useRef<HTMLInputElement>(null);
  const numberFiveRef = useRef<HTMLInputElement>(null);
  const numberSixRef = useRef<HTMLInputElement>(null);

  const refs = [
    numberOneRef,
    numberTwoRef,
    numberThreeRef,
    numberFourRef,
    numberFiveRef,
    numberSixRef,
  ];

  const handleTabKey = (
    event: React.KeyboardEvent<HTMLInputElement>,
    currentRef: React.RefObject<HTMLInputElement>,
    prevRef: React.RefObject<HTMLInputElement> | null,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (event.key === "Backspace" && code.length <= 6) {
        setIsButtonEnabled(false);
      }
      if (event.currentTarget.value === "" && event.key === "Backspace") {
        setIsButtonEnabled(false);
        event.preventDefault();
        if (prevRef && prevRef.current) {
          prevRef.current.focus();
          prevRef.current.setSelectionRange(1, 1);
        }
      }
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();
      if (prevRef && prevRef.current) {
        prevRef.current.focus();
        prevRef.current.setSelectionRange(1, 1);
      }
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      if (nextRef.current) {
        nextRef.current.focus();
        nextRef.current.setSelectionRange(0, 0);
      }
    } else if (event.key === "Tab") {
      event.preventDefault();
      if (event.shiftKey) {
        if (prevRef && prevRef.current) {
          prevRef.current.focus();
          prevRef.current.setSelectionRange(0, 0);
        }
      } else {
        if (nextRef.current) {
          nextRef.current.focus();
          nextRef.current.setSelectionRange(0, 0);
        }
      }
    }
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    const value = event.target.value.slice(0, 1);
    event.target.value = value;

    if (value !== "") {
      navigateToNextInput(nextRef);
    }
    if (code.length === 5) {
      setIsButtonEnabled(true);
      setErrorMgs("");
    }

    setPassValidation({
      ...passValidation,
      [event.target.name]: value.toUpperCase(),
    });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    prevRef: React.RefObject<HTMLInputElement> | null,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (event.currentTarget.value === "") {
        event.preventDefault();
        /* if (prevRef && prevRef.current) {
          prevRef.current.focus() //define o focus para o elemento anterior;
          prevRef.current.setSelectionRange(1, 1, "forward") posiciona o cursor no final do elemento anterior;
        } */
      }
    }
  };

  const handleExpire = () => {
    setTimeOut(true);
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData("text/plain");

    if (pastedData) {
      const pastedValues = pastedData.slice(0, 6).split("");
      for (let i = 0; i < pastedValues.length; i++) {
        setPassValidation({
          numberOne: pastedValues[0],
          numberTwo: pastedValues[1],
          numberThree: pastedValues[2],
          numberFour: pastedValues[3],
          numberFive: pastedValues[4],
          numberSix: pastedValues[5],
        });

        const currentValue = pastedValues[i];
        if (currentValue !== undefined && refs[index + i]) {
          const currentRef = refs[index + i].current;
          if (currentRef) {
            currentRef.value = currentValue;
            const nextRef = refs[index + i + 1]?.current;
            if (nextRef) {
              nextRef.focus();
            }
          }
        }
      }
      setIsButtonEnabled(true);
    }
  };

  const handleClickReenvio = () => {
    setTimeOut(false);

    initAuth.mutate({email: emailStorage});
  };

  const handleBackButtonClick = () => {
    navigate("/contact");
  };

  const handleButtonClick = () => {
    mutation.mutate({ email: emailStorage, token: code });
  };

  const renderCountdown = useMemo(() => {
    return <CountdownTimer seconds={40} handleComplete={handleExpire} />;
  }, []);

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("validation")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-info-validation"
        title={title}
        description={description}
      >
        <div className="input-container">
          <div className="inputs-insider-container">
            <input
              type="text"
              value={passValidation.numberOne}
              name="numberOne"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberTwoRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberOneRef, null, numberTwoRef)
              }
              onKeyUp={(e) => handleKeyDown(e, null, numberTwoRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberOneRef}
            />
            <input
              type="text"
              value={passValidation.numberTwo}
              name="numberTwo"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberThreeRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberTwoRef, numberOneRef, numberThreeRef)
              }
              onKeyUp={(e) => handleKeyDown(e, numberOneRef, numberThreeRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberTwoRef}
            />
            <input
              type="text"
              value={passValidation.numberThree}
              name="numberThree"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberFourRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberThreeRef, numberTwoRef, numberFourRef)
              }
              onKeyUp={(e) => handleKeyDown(e, numberTwoRef, numberFourRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberThreeRef}
            />
            <input
              type="text"
              value={passValidation.numberFour}
              name="numberFour"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberFiveRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberFourRef, numberThreeRef, numberFiveRef)
              }
              onKeyUp={(e) => handleKeyDown(e, numberThreeRef, numberFiveRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberFourRef}
            />
            <input
              type="text"
              value={passValidation.numberFive}
              name="numberFive"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberSixRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberFiveRef, numberFourRef, numberSixRef)
              }
              onKeyUp={(e) => handleKeyDown(e, numberFourRef, numberSixRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberFiveRef}
            />
            <input
              type="text"
              value={passValidation.numberSix}
              name="numberSix"
              className="custom-input-big"
              maxLength={1}
              onChange={(e) => handleNumberChange(e, numberSixRef)}
              onKeyDown={(e) =>
                handleTabKey(e, numberSixRef, numberFiveRef, numberFourRef)
              }
              onKeyUp={(e) => handleKeyDown(e, numberFiveRef, numberFourRef)}
              onPaste={(e) => handlePaste(e, 0)}
              ref={numberSixRef}
            />
          </div>
          {invalidNumber && <p className="error-mgs-validation">{errorMgs}</p>}
          <div className="score-container">
            <p
              className={timeOut ? "text-send-error" : "text-Send"}
              onClick={handleClickReenvio}
            >
              Reenviar código:{" "}
            </p>

            {timeOut ? <span>00:00</span> : renderCountdown}
          </div>
          <div className="button-container-validation">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar"
              onClick={handleBackButtonClick}
            >
              Voltar
            </ButtonComponent>

            <ButtonComponent
              className="continue-button"
              // className="btn-contact"
              onClick={handleButtonClick}
              isDisabled={!isButtonEnabled ? true : false}
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>
        {(getProtectedLead.isPending ||
          getDistribuidora.isPending ||
          getLead.isPending ||
          createLead.isPending ||
          initAuth.isPending ||
          mutation.isPending) && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
