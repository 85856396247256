import { api } from "../lib/axios/config";

type ObjetoSendTokenProps = {
  email: string;
  token: string;
};

export async function initAuth(
  { email, name }: { email: string; name?: string },
  token?: string
) {
  const response = await api.post(
    "auth/init",
    {
      email,
      name,
    },
    {
      headers: {
        "x-recaptcha-token": token,
      },
    }
  );
  return response.data;
}

export async function sendToken(objetoSendToken: ObjetoSendTokenProps) {
  const response = await api.post(
    "auth/generate-signed-token",
    objetoSendToken
  );
  // console.log("authentication:", response.data);
  return response.data;
}

export async function generateSignedTokenFromSafelink(safelinkToken: string) {
  const response = await api.post("auth/generate-signed-token-from-safelink", {
    token: safelinkToken,
  });
  return response.data;
}
