import React, { useRef } from "react";
import "./LeftHomeComponent.css";
import { ModalTermosDeUso } from "../modal/ModalTermsUso/ModelTermosDeUso";
import { ModalRef } from "../../utils/modal";
import { navigateToLp } from "../../utils/landingPage";

interface LeftHomeProps {
  logoSrc: string;
  title: string;
  description: string;
  imgSrc: string;
  termUrl?: string; // Torna termUrl opcional
}

const LeftHomeComponent: React.FC<LeftHomeProps> = ({
  logoSrc,
  title,
  description,
  imgSrc
}) => {
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);

  return (
    <div className="left-home">
      <div className="container-logo-title-description-left-home">
        <img
          src={logoSrc}
          alt="logo-ivi"
          className="logo-ivi"
          style={{ cursor: "pointer" }}
          onClick={navigateToLp}
        />
        <div>
          <h3 className="h3-custom">{title}</h3>
          {description.split('\n').map(d => <p key={d}>{d}</p>)}
        </div>
      </div>
      {/* <div className="container-img">
        </div> */}
      <img src={imgSrc} alt="img-esquerda" className="img-ivi-esquerdo" />
      <ModalTermosDeUso ref={modalTermosDeUsoRef}/>

      <p className="termo-adesao" onClick={() => modalTermosDeUsoRef.current?.open()}>
        Termos de Uso e Polticia de Privacidade
      </p>
      {/* <div className="container-left-home">
      </div> */}
    </div>
  );
};

export default LeftHomeComponent;
